<template>
  <div class="details">
    <headbar :title="$t('详情')"></headbar>

    <div class="card">
      <div class="title">{{ news.newstitle }}</div>
      <div class="date">{{ news.newstime }}</div>
      <div class="content" v-html="news.newscontent"></div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import CustomComponent from "@/components/CustomComponent";

export default {
  name: '',
  components: {},
  setup() {
    return {
      news: ref({}),
      nid: ref("")
    }
  },
  created() {
    this.nid = this.$route.query.id
    this.getData()
  },
  methods: {
    getData() {
      let _this = this
      _this.$request.post(
        "api/News/Get",
        {
          select_id: _this.nid
        },
        (res) => {
          if (res.data.code == 0) {
            _this.$q.dialog({
              component: CustomComponent,
              componentProps: {
                messages: [res.data.msg],
                persistent: true,
              }
            });
            return
          }
          let data = res.data.data
          _this.news = data
        }
      )
    }
  }
}
</script>

<style scoped>
.details {
  min-height: 100vh;
  padding: 10px;
}

.card {
  padding: 10px;
  height: calc(100vh - 70px);
  border-radius: 10px;
  background-color: #1b1b1b;
  overflow-y: auto;
  color: #fff;
}

.title {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}

.date {
  margin-top: 10px;
  font-size: 14px;
  color: #999;
  text-align: center;
}
</style>